import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Button,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Title from "../../Title"
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import * as Yup from "yup";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import styles from "./ValetAreaEditor.styles";
import moment from "moment-timezone";
import MuiPhoneNumber from "material-ui-phone-number";
import ValetAreaService from "../../../services/ValetAreaService";
import {
  transformValetAreaDataIngress,
  transformValetAreaDataEgress
} from "./utilities";
import { useSelector, useDispatch } from "react-redux";
import {
  entityUpdate,
  onboardValetArea
} from "../../../state/slices/entities";
import { useCountry } from '../../../hooks/useCountry';
import { COUNTRY_CODES } from '../../../constants/index';
import CountryService from '../../../services/CountryService';


export const ValidationSchema = Yup.object().shape({
  ValetAreaName: Yup.string()
    .trim("No leading or trailing whitespaces...")
    .required("Required")
    .min(3)
    .max(30, "Cannot exceed 30 characters"),
  TimeZone: Yup.string(),
  ValetAreaAddress1: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  ValetAreaAddress2: Yup.string(),
  ValetAreaCity: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  ValetAreaCountryID: Yup.number().typeError("Must be a number").required("Required"),
  ValetAreaState: Yup.string(),
  ValetAreaSubdivisionID: Yup.number().typeError("Must be a number").required("Required"),
  ValetAreaZip: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryFirstName: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryLastName: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryAddress1: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryAddress2: Yup.string(),
  PrimaryCity: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryState: Yup.string(),
  PrimarySubdivisionID: Yup.number().typeError("Must be a number").required("Required"),
  PrimaryCountryID: Yup.number().typeError("Must be a number").required("Required"),
  PrimaryZip: Yup.string().trim("No leading or trailing whitespaces...").required("Required"),
  PrimaryPhone1: Yup.string().required("Required"),
  PrimaryPhone2: Yup.string(),
  PrimaryEMailAddress: Yup.string().email("Invalid email").required("Required"),
  SecondaryFirstName: Yup.string().trim("No leading or trailing whitespaces..."),
  SecondaryLastName: Yup.string().trim("No leading or trailing whitespaces..."),
  SecondaryAddress1: Yup.string().trim("No leading or trailing whitespaces..."),
  SecondaryAddress2: Yup.string(),
  SecondaryCity: Yup.string().trim("No leading or trailing whitespaces..."),
  SecondaryState: Yup.string(),
  SecondaryZip: Yup.string().trim("No leading or trailing whitespaces..."),
  SecondaryPhone1: Yup.string(),
  SecondaryPhone2: Yup.string(),
  SecondaryEMailAddress: Yup.string().email("Invalid email")
});

const countryService = new CountryService(apiClient);
const valetareaService = new ValetAreaService(apiClient);

const ValetAreaEditor = ({
  entityID,
  parentEntityID,
  onSubmitComplete,
  onCancel
}) => {

  const currentUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { countries } = useCountry();

  const [valetareaData, setValetAreaData] = useState({
    parentEntityID: parentEntityID,
    ValetAreaName: "",
    TimeZone: moment.tz.guess() ?? "",
    ValetAreaAddress1: "",
    ValetAreaAddress2: "",
    ValetAreaCountryID: "",
    ValetAreaSubdivisionID: "",
    ValetAreaCity: "",
    ValetAreaState: "",
    ValetAreaZip: "",
    PrimaryFirstName: "",
    PrimaryLastName: "",
    PrimaryAddress1: "",
    PrimaryAddress2: "",
    PrimaryCountryID: "",
    PrimaryCity: "",
    PrimaryState: "",
    PrimarySubdivisionID: "",
    PrimaryZip: "",
    PrimaryPhone1: "",
    PrimaryPhone2: "",
    PrimaryEMailAddress: "",
    SecondaryFirstName: "",
    SecondaryLastName: "",
    SecondaryAddress1: "",
    SecondaryAddress2: "",
    SecondaryCountryID: "",
    SecondaryCity: "",
    SecondarySubdivisionID: "",
    SecondaryState: "",
    SecondaryZip: "",
    SecondaryPhone1: "",
    SecondaryPhone2: "",
    SecondaryEMailAddress: ""
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...valetareaData },
    resolver: yupResolver(ValidationSchema),
    shouldUnregister: false,
  });

  const style = styles();
  const isEditMode = entityID != null;
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [valetareaSubdivisions, setValetAreaSubdivisions] = useState([]);
  const [valetareaSubdivisionLabel, setValetAreaSubdivisionLabel] = useState('State/Province');
  const [primarySubdivisions, setPrimarySubdivisions] = useState([]);
  const [primarySubdivisionLabel, setPrimarySubdivisionLabel] = useState('State/Province');
  const [secondarySubdivisions, setSecondarySubdivisions] = useState([]);
  const [secondarySubdivisionLabel, setSecondarySubdivisionLabel] = useState('State/Province');

  const getSubdivisonLabelValue = (countryId) => {
    if (countryId === COUNTRY_CODES.UnitedStates) {
      return 'State';
    } else if (countryId === COUNTRY_CODES.Canada) {
      return 'Province';
    }
  }
  const onValetAreaCountryChanged = async (countryId) => {
    try {
      const response = await countryService.getSubdivisions(countryId);

      if (getValues("ValetAreaSubdivisionID")) {
        setValue('ValetAreaSubdivisionID', '');
      }

      setValetAreaSubdivisions(response.data);
      setValetAreaSubdivisionLabel(getSubdivisonLabelValue(countryId))
      setValue('ValetAreaCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get valet area subdivisions: ${error}`,
        { variant: "error", tag: "FailedToGetvaletareaSubdivisions" }
      );
    }
  }

  const onPrimaryCountryChanged = async (countryId) => {
    try {
      const response = await countryService.getSubdivisions(countryId);

      if (getValues("PrimarySubdivisionID")) {
        setValue('PrimarySubdivisionID', '');
      }

      setPrimarySubdivisions(response.data);
      setPrimarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      setValue('PrimaryCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get primary subdivisions: ${error}`,
        { variant: "error", tag: "FailedToGetPrimarySubdivisions" }
      );
    }
  }

  const onSecondaryCountryChanged = async (countryId) => {
    try {
      const response = await countryService.getSubdivisions(countryId);

      if (getValues("SecondarySubdivisionID")) {
        setValue('SecondarySubdivisionID', '');
      }

      setSecondarySubdivisions(response.data);
      setSecondarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      setValue('SecondaryCountryID', countryId);
    } catch (error) {
      enqueueSnackbar(
        `Failed to get secondary subdivisions: ${error}`,
        { variant: "error", tag: "FailedToGetSecondarySubdivisions" }
      );
    }
  }

  useEffect(() => {
    var zoneList = moment.tz.zonesForCountry("US");
    var zoneOptions = [];
    for (var i = 0; i < zoneList.length; i++) {
      zoneOptions.push(
        <MenuItem value={zoneList[i]} key={zoneList[i]}>
          {zoneList[i].replaceAll("_", " ")}
        </MenuItem>
      );
    }
    setTimezoneOptions(zoneOptions);
  }, []);

  const handleCancel = () => {
    onCancel();
  };
  const onSubmit = async (values) => {
    await createOrUpdateEntity(values);
  };

  const createOrUpdateEntity = async values => {
    const transformed = transformValetAreaDataEgress(values);
    transformed.parentEntityID = parentEntityID;
    let _payload = {};
    if (isEditMode) {
      transformed.valetareaID = entityID;//values.valetareaID;
      _payload.entity = transformed;
      _payload.path = "valetarea";
    } else _payload = transformed;
    const result = isEditMode
      ? await dispatch(entityUpdate(_payload))
      : await dispatch(onboardValetArea(_payload));

    if (result.error) {
      enqueueSnackbar(
        `We encountered an error while ${isEditMode ? "updating" : "creating"
        } the Valet Area`,
        { variant: "error", tag: "FailedToCreateorUpdateValetArea" }
      );
      return;
    }

    onSubmitComplete();
  };

  const enqueueSnackbar = useEnqueueSnackbar();
  useEffect(() => {
    const work = async () => {
      let response;
      try {
        response = await valetareaService.getEntity(
          entityID
        );
      } catch (err) {
        enqueueSnackbar("Failed to retrieve valetarea info", {
          variant: "error",
          tag: "FailedToRetriveValetArea"
        });
        return;
      }

      const transformed = transformValetAreaDataIngress(response.data);

      setValetAreaData(prev => ({
        ...prev,
        ...transformed,
        parentEntityID: response.data.parentEntityID,
        valetareaID: response.data.valetAreaID
      }));
    }

    if (entityID && parentEntityID && isEditMode) {
      work();
    }
  }, [entityID, parentEntityID]);

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setValetAreaSubdivisions(response.data)
        setValetAreaSubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get ValetArea subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetValetAreaSubdivisions" }
        );
      }
    }
    if (valetareaData.ValetAreaCountryID) {
      getSubdivisions(valetareaData.ValetAreaCountryID)
    }
  }, [valetareaData.ValetAreaCountryID])

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setPrimarySubdivisions(response.data)
        setPrimarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get primary subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetPrimarySubdivisions" }
        );
      }
    }
    if (valetareaData.PrimaryCountryID) {
      getSubdivisions(valetareaData.PrimaryCountryID)
    }
  }, [valetareaData.PrimaryCountryID])

  useEffect(() => {
    const getSubdivisions = async (countryId) => {
      try {
        const response = await countryService.getSubdivisions(countryId);

        setSecondarySubdivisions(response.data)
        setSecondarySubdivisionLabel(getSubdivisonLabelValue(countryId))
      } catch (error) {
        enqueueSnackbar(
          `Failed to get secondary subdivisons: ${error}`,
          { variant: "error", tag: "FailedToGetSecondarySubdivisions" }
        );
      }
    }
    if (valetareaData.SecondaryCountryID) {
      getSubdivisions(valetareaData.SecondaryCountryID)
    }
  }, [valetareaData.SecondaryCountryID])

  //This useEffect is used for loading of data on the form during
  //Update
  useEffect(() => {
    reset(valetareaData);
  }, [valetareaData]);


  return (
    <>
      <div>
        <Grid classname={clsx("valetarea-title")} data-testid="title" container spacing={2}>
          <Grid item xs={12}>
            <Title>{isEditMode ? "Edit Valet Area" : "Create Valet Area"}</Title>
          </Grid>
        </Grid>
        <form className={clsx(style.root, "valetarea-editor")}
        >
          {/* Main Valet Area */}
          <div className={style.top}>
            <Grid item xs={12}>
              <Controller
                name="ValetAreaName"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="ValetAreaName *" role="valet-area-name">
                    <TextField
                      {...field}
                      id="ValetAreaName"
                      className={clsx("ValetAreaName")}
                      label="Valet Area Name *"
                      variant="outlined"
                      error={!!errors.ValetAreaName}
                      helperText={errors.ValetAreaName && errors.ValetAreaName.message}
                      disabled={!currentUser.isAdmin}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </div>
        </form>

        <Box>
          <Box className={style.grouping} borderColor="grey.main" border={1}>
            Valet Area Address
            <Grid>
              <Controller
                name="TimeZone"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="TimeZone" margin="1">
                    <TextField
                      {...field}
                      id="TimeZone"
                      className={style.Top}
                      label="Time Zone"
                      data-testid="timezone-select"
                      select
                      variant="outlined"
                      error={!!errors.TimeZone}
                      helperText={errors.TimeZone && errors.TimeZone.message}
					            disabled={!currentUser.isAdmin}
                    >
                      {timezoneOptions}
                    </TextField>
                  </FormControl>
                )}
              />
            </Grid>
            <Controller
              name="ValetAreaAddress1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="Address 1 *" role="valet-area-address1">
                  <TextField
                    {...field}
                    id="ValetAreaAddress1"
                    className={clsx("ValetAreaAddress1")}
                    label="Address 1 *"
                    variant="outlined"
                    error={!!errors.ValetAreaAddress1}
                    helperText={errors.ValetAreaAddress1 && errors.ValetAreaAddress1.message}
					          disabled={!currentUser.isAdmin}
                  />
                </FormControl>
              )}
            />
            <Controller
              name="ValetAreaAddress2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="Address 2" role="valet-area-address2">
                  <TextField
                    {...field}
                    id="ValetAreaAddress2"
                    className={clsx("ValetAreaAddress2")}
                    label="Address 2"
                    variant="outlined"
                    error={!!errors.ValetAreaAddress2}
                    helperText={errors.ValetAreaAddress2 && errors.ValetAreaAddress2.message}
					          disabled={!currentUser.isAdmin}
                  />
                </FormControl>
              )}
            />
            <Controller
              name="ValetAreaCountryID"
              control={control}
              render={({ field }) => (
                <FormControl FormLabel="Country *">
                  <TextField
                    {...field}
                    id="ValetAreaCountryID"
                    className={style.textField}
                    label="Country *"
                    onChange={(event, selected) => {
                      onValetAreaCountryChanged(selected.props.value);
                    }}
                    variant="outlined"
                    error={!!errors.ValetAreaCountryID}
                    helperText={errors.ValetAreaCountryID && errors.ValetAreaCountryID.message}
                    select
                    data-id="valetarea-country-select"
                    data-testid='valetarea-country-textfield'
                    SelectProps={{
                      SelectDisplayProps: { 'data-testid': 'valetarea-country-select' }
                    }}
                    size='small'
                    disabled={!currentUser.isAdmin}
                  >
                    {countries && countries.length > 0 && countries.map((country) => {
                      return (
                        <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                          {country.countryName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              )}
            />
            <Controller
              name="ValetAreaCity"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="City *" role="valet-area-city">
                  <TextField
                    {...field}
                    id="ValetAreaCity"
                    className={clsx("ValetAreaCity")}
                    label="City *"
                    variant="outlined"
                    error={!!errors.ValetAreaCity}
                    helperText={errors.ValetAreaCity && errors.ValetAreaCity.message}
					          disabled={!currentUser.isAdmin}
                  />
                </FormControl>
              )}
            />
            <Controller
              name="ValetAreaSubdivisionID"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel={`${valetareaSubdivisionLabel} *`}>
                  <TextField
                    {...field}
                    id="ValetAreaSubdivisionID"
                    className={style.textField}
                    label={`${valetareaSubdivisionLabel} *`}
                    variant="outlined"
                    data-id="valetarea-subdivision-select"
                    data-testid='valetarea-subdivision-textfield'
                    labelId='ValetAreaSubdivisionLabel'
                    error={!!errors.ValetAreaSubdivisionID}
                    helperText={errors.ValetAreaSubdivisionID && errors.ValetAreaSubdivisionID.message}
                    select
                    SelectProps={{
                      SelectDisplayProps: { 'data-testid': 'valetarea-subdivision-select' }
                    }}
                    size='small'
                    disabled={!currentUser.isAdmin}
                  >
                    {valetareaSubdivisions && valetareaSubdivisions.length > 0 && valetareaSubdivisions.map((valetareaSubdivision) => {
                      return (
                        <MenuItem
                          className={clsx(`${valetareaSubdivision.subdivisionName}`)}
                          key={valetareaSubdivision.subdivisionID}
                          value={valetareaSubdivision.subdivisionID}
                          fieldName="ValetAreaSubdivisionID"
                        >
                          {valetareaSubdivision.subdivisionName}
                        </MenuItem>
                      );
                    })}
                    {<MenuItem className={clsx(`select-a-country`)} value='' fieldName="ValetAreaSubdivisionID">
                      Select a country
                    </MenuItem>
                    }
                  </TextField>
                </FormControl>
              )}
            />
            <Controller
              name="ValetAreaZip"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="Zip *" role="valet-area-zip">
                  <TextField
                    {...field}
                    id="ValetAreaZip"
                    className={clsx("ValetAreaZip")}
                    label="Zip *"
                    variant="outlined"
                    error={!!errors.ValetAreaZip}
                    helperText={errors.ValetAreaZip && errors.ValetAreaZip.message}
					          disabled={!currentUser.isAdmin}
                  />
                </FormControl>
              )}
            />
          </Box>
          <div className={style.inline}>
            <Box className={style.grouping} borderColor="grey.main" border={1}>
              Primary Contact
              <Controller
                name="PrimaryFirstName"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="First Name *">
                    <TextField
                      {...field}
                      id="PrimaryFirstName"
                      className={style.textField}
                      label="First Name *"
                      variant="outlined"
                      error={!!errors.PrimaryFirstName}
                      helperText={errors.PrimaryFirstName && errors.PrimaryFirstName.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryLastName"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Last Name *">
                    <TextField
                      {...field}
                      id="PrimaryLastName"
                      className={style.textField}
                      label="Last Name *"
                      variant="outlined"
                      error={!!errors.PrimaryLastName}
                      helperText={errors.PrimaryLastName && errors.PrimaryLastName.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryAddress1"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Address 1 *" role="primary-address1">
                    <TextField
                      {...field}
                      id="PrimaryAddress1"
                      className={clsx("PrimaryAddress1")}
                      label="Address 1 *"
                      variant="outlined"
                      error={!!errors.PrimaryAddress1}
                      helperText={errors.PrimaryAddress1 && errors.PrimaryAddress1.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryAddress2"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="PrimaryAddress2" role="primary-address2">
                    <TextField
                      {...field}
                      id="PrimaryAddress2"
                      className={clsx("PrimaryAddress2")}
                      label="Address 2"
                      variant="outlined"
                      error={!!errors.PrimaryAddress2}
                      helperText={errors.PrimaryAddress2 && errors.PrimaryAddress2.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryCountryID"
                control={control}
                render={({ field }) => (
                  <FormControl FormLabel="Country *">
                    <TextField
                      {...field}
                      id="PrimaryCountryID"
                      className={style.textField}
                      label="Country *"
                      onChange={(event, selected) => {
                        onPrimaryCountryChanged(selected.props.value);
                      }}
                      variant="outlined"
                      error={!!errors.PrimaryCountryID}
                      helperText={errors.PrimaryCountryID && errors.PrimaryCountryID.message}
                      select
                      data-id="primary-country-select"
                      SelectProps={{
                        SelectDisplayProps: { 'data-testid': 'primary-country-select' }
                      }}
                      size='small'
                      FormHelperTextProps={{
                        'data-testid': 'primary-country-helpertext'
                      }}
                    >
                      {countries && countries.length > 0 && countries.map((country) => {
                        return (
                          <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                            {country.countryName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryCity"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="City *" role="primary-city">
                    <TextField
                      {...field}
                      id="PrimaryCity"
                      className={clsx("PrimaryCity")}
                      label="City *"
                      variant="outlined"
                      error={!!errors.PrimaryCity}
                      helperText={errors.PrimaryCity && errors.PrimaryCity.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimarySubdivisionID"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel={`${primarySubdivisionLabel} *`}>
                    <TextField
                      {...field}
                      id="PrimarySubdivisionID"
                      className={style.textField}
                      label={`${primarySubdivisionLabel} *`}
                      variant="outlined"
                      data-id="primary-subdivision-select"
                      data-testid='primary-subdivision-textfield'
                      labelId='PrimarySubdivisionLabel'
                      error={!!errors.PrimarySubdivisionID}
                      helperText={errors.PrimarySubdivisionID && errors.PrimarySubdivisionID.message}
                      select
                      SelectProps={{
                        SelectDisplayProps: { 'data-testid': 'primary-subdivision-select' }
                      }}
                      FormHelperTextProps={{
                        'data-testid': 'primary-subdivision-helpertext'
                      }}
                      size='small'
                    >
                      {primarySubdivisions && primarySubdivisions.length > 0 && primarySubdivisions.map((primarySubdivision) => {
                        return (
                          <MenuItem
                            className={clsx(`${primarySubdivision.subdivisionName}`)}
                            key={primarySubdivision.subdivisionID}
                            value={primarySubdivision.subdivisionID}
                            fieldName="PrimarySubdivisionID"
                          >
                            {primarySubdivision.subdivisionName}
                          </MenuItem>
                        );
                      })}
                      {<MenuItem className={clsx(`select-a-country`)} value='' fieldName="PrimarySubdivisionID">
                        Select a country
                      </MenuItem>
                      }
                    </TextField>
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryZip"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Zip *" role="secondary-zip">
                    <TextField
                      {...field}
                      id="PrimaryZip"
                      className={clsx("PrimaryZip")}
                      label="Zip *"
                      variant="outlined"
                      error={!!errors.PrimaryZip}
                      helperText={errors.PrimaryZip && errors.PrimaryZip.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryPhone1"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Phone 1 *">
                    <MuiPhoneNumber
                      {...field}
                      id="PrimaryPhone1"
                      className={style.textField}
                      label="Phone 1 *"
                      variant="outlined"
                      data-testid='PrimaryPhone1'
                      error={!!errors.PrimaryPhone1}
                      helperText={errors.PrimaryPhone1 && errors.PrimaryPhone1.message}
                      defaultCountry={"us"}
                      regions={["north-america"]}
                      disableAreaCodes
                      disableCountryCode
                      onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
                      excludeCountries={['pm', 'bm']}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryPhone2"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Phone 2">
                    <MuiPhoneNumber
                      {...field}
                      id="PrimaryPhone2"
                      className={style.textField}
                      label="Phone 2"
                      variant="outlined"
                      data-testid='PrimaryPhone2'
                      error={!!errors.PrimaryPhone2}
                      helperText={errors.PrimaryPhone2 && errors.PrimaryPhone2.message}
                      defaultCountry={"us"}
                      regions={["north-america"]}
                      disableAreaCodes
                      disableCountryCode
                      onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
                      excludeCountries={['pm', 'bm']}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="PrimaryEMailAddress"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="eMail Address *">
                    <TextField
                      {...field}
                      id="PrimaryEMailAddress"
                      className={style.textField}
                      label="eMail Address *"
                      variant="outlined"
                      error={!!errors.PrimaryEMailAddress}
                      helperText={errors.PrimaryEMailAddress && errors.PrimaryEMailAddress.message}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box className={style.grouping} borderColor="grey.main" border={1}>
              Secondary Contact
              <Controller
                name="SecondaryFirstName"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="First Name">
                    <TextField
                      {...field}
                      id="SecondaryFirstName"
                      className={style.textField}
                      label="First Name "
                      variant="outlined"
                      value={field.value || ""}
                      error={!!errors.SecondaryFirstName}
                      helperText={errors.SecondaryFirstName && errors.SecondaryFirstName.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryLastName"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Last Name">
                    <TextField
                      {...field}
                      id="SecondaryLastName"
                      className={style.textField}
                      label="Last Name "
                      variant="outlined"
                      error={!!errors.SecondaryLastName}
                      helperText={errors.SecondaryLastName && errors.SecondaryLastName.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryAddress1"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="SecondaryAddress1" role="secondary-address1">
                    <TextField
                      {...field}
                      id="SecondaryAddress1"
                      className={clsx("SecondaryAddress1")}
                      label="Address 1 "
                      variant="outlined"
                      error={!!errors.SecondaryAddress1}
                      helperText={errors.SecondaryAddress1 && errors.SecondaryAddress1.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryAddress2"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="SecondaryAddress2" role="secondary-address2">
                    <TextField
                      {...field}
                      id="SecondaryAddress2"
                      className={clsx("SecondaryAddress2")}
                      label="Address 2"
                      variant="outlined"
                      error={!!errors.SecondaryAddress2}
                      helperText={errors.SecondaryAddress2 && errors.SecondaryAddress2.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryCountryID"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Country">
                    <TextField
                      {...field}
                      id="SecondaryCountryID"
                      className={style.textField}
                      label="Country"
                      onChange={(event, selected) => {
                        onSecondaryCountryChanged(selected.props.value);
                      }}
                      variant="outlined"
                      error={!!errors.SecondaryCountryID}
                      helperText={errors.SecondaryCountryID && errors.SecondaryCountryID.message}
                      select
                      data-id="secondary-country-select"
                      data-testid='secondary-country-textfield'
                      SelectProps={{
                        SelectDisplayProps: { 'data-testid': 'secondary-country-select' }
                      }}
                      size='small'
                    >
                      {countries && countries.length > 0 && countries.map((country) => {
                        return (
                          <MenuItem className={clsx(`${countries.countryName}`)} key={country.countryID} value={country.countryID}>
                            {country.countryName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryCity"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="City" role="secondary-city">
                    <TextField
                      {...field}
                      id="SecondaryCity"
                      className={clsx("SecondaryCity")}
                      label="City"
                      variant="outlined"
                      data-testid='valetarea-country-helpertext'
                      error={!!errors.SecondaryCity}
                      helperText={errors.SecondaryCity && errors.SecondaryCity.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondarySubdivisionID"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel={`${secondarySubdivisionLabel} `}>
                    <TextField
                      {...field}
                      id="SecondarySubdivisionID"
                      className={style.textField}
                      label={`${secondarySubdivisionLabel} `}
                      variant="outlined"
                      data-id="secondary-subdivision-select"
                      data-testid='secondary-subdivision-textfield'
                      labelId='SecondarySubdivisionLabel'
                      error={!!errors.SecondarySubdivisionID}
                      helperText={errors.SecondarySubdivisionID && errors.SecondarySubdivisionID.message}
                      select
                      SelectProps={{
                        SelectDisplayProps: { 'data-testid': 'secondary-subdivision-select' }
                      }}
                      FormHelperTextProps={{
                        'data-testid': 'secondary-subdivision-helpertext'
                      }}
                      size='small'
                    >
                      {secondarySubdivisions && secondarySubdivisions.length > 0 && secondarySubdivisions.map((secondarySubdivision) => {
                        return (
                          <MenuItem
                            className={clsx(`${secondarySubdivision.subdivisionName}`)}
                            key={secondarySubdivision.subdivisionID}
                            value={secondarySubdivision.subdivisionID}
                            fieldName="SecondarySubdivisionID"
                          >
                            {secondarySubdivision.subdivisionName}
                          </MenuItem>
                        );
                      })}
                      {<MenuItem className={clsx(`select-a-country`)} value='' fieldName="PrimarySubdivisionID">
                        Select a country
                      </MenuItem>
                      }
                    </TextField>
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryZip"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Zip" role="secondary-zip">
                    <TextField
                      {...field}
                      id="SecondaryZip"
                      className={clsx("SecondaryZip")}
                      label="Zip"
                      variant="outlined"
                      error={!!errors.SecondaryZip}
                      helperText={errors.SecondaryZip && errors.SecondaryZip.message}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryPhone1"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Phone 1" role="secondary-phone1">
                    <MuiPhoneNumber
                      {...field}
                      id="SecondaryPhone1"
                      className={style.textField}
                      label="Phone 1"
                      variant="outlined"
                      data-testid='SecondaryPhone1'
                      error={!!errors.SecondaryPhone1}
                      helperText={errors.SecondaryPhone1 && errors.SecondaryPhone1.message}
                      defaultCountry={"us"}
                      regions={["north-america"]}
                      disableAreaCodes
                      disableCountryCode
                      onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
                      excludeCountries={['pm', 'bm']}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryPhone2"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Phone 2" role="secondary-phone2">
                    <MuiPhoneNumber
                      {...field}
                      id="SecondaryPhone2"
                      className={style.textField}
                      label="Phone 2"
                      variant="outlined"
                      data-testid='SecondaryPhone2'
                      error={!!errors.SecondaryPhone2}
                      helperText={errors.SecondaryPhone2 && errors.SecondaryPhone2.message}
                      defaultCountry={"us"}
                      regions={["north-america"]}
                      disableAreaCodes
                      disableCountryCode
                      onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
                      excludeCountries={['pm', 'bm']}
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="SecondaryEMailAddress"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="eMail Address">
                    <TextField
                      {...field}
                      id="SecondaryEMailAddress"
                      className={style.textField}
                      label="eMail Address"
                      variant="outlined"
                      error={!!errors.SecondaryEMailAddress}
                      helperText={errors.SecondaryEMailAddress && errors.SecondaryEMailAddress.message}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </div>
        </Box>
        <div className={style.btnGroup}>
          <Button
            color="primary"
            name="submit"
            type="submit"
            variant="contained"
            className={clsx("saveBtn", style.btn)}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
            data-testid="saveBtn"
          >
            {isEditMode ? "Update" : "Save"}
          </Button>
          <Button
            className={clsx("cancelBtn", style.btn)}
            name="cancel"
            color="secondary"
            variant="contained"
            onClick={handleCancel}
            disabled={isSubmitting}
            data-testid="cancelBtn"
          >
            Cancel
          </Button>
        </div>
      </div >
    </>
  );
};

ValetAreaEditor.defaultProps = {
  props: {},
  onSubmitComplete: () => { },
  onCancel: () => { }
};

ValetAreaEditor.propTypes = {
  entityID: PropTypes.string,
  parentEntityID: PropTypes.string.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ValetAreaEditor;