import React, { useCallback, useEffect, useState }  from "react";
import { useSelector, useDispatch }                 from 'react-redux';
import { TextField }                                from "formik-material-ui";
import { Button,  Divider, Grid,  IconButton,  Typography }      from "@material-ui/core";
import { Formik, Form, Field }                      from "formik";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import * as Yup                                     from "yup";
import PropTypes  from "prop-types";
import SaveIcon   from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import styles                   from "./DeviceEditor.styles";
import { 
    FindEntity,
    entityUpdate,
    entityCreate,
    entityDelete,
    entitySettingsUpdate,
}   from "../../../state/slices/entities";
import Title                        from "../../Title";
import DeviceService from "../../../services/DeviceService";
import apiClient from "../../../auth/apiClient";
import * as c from "../../../constants";

const deviceService = new DeviceService(apiClient);

const DeviceEditor = ({entityID, parentEntityID, parentEntityData, onCancel, onSaveComplete, onDeleteComplete}) =>{
    const classes       = styles();
    const dispatch      = useDispatch();
    const entities      = useSelector(state => state.entities);
    const currentUser   = useSelector(state => state.user);    
    const isEditMode    = entityID !=null;

    const enqueueSnackbar = useEnqueueSnackbar();
    const [entity, setEntity] = useState(
        (entityID)
            ? FindEntity(entities.EntityList, entityID)                                     //edit
            : {entityid: null, parententityid: parentEntityID, name: "", description:""}    //create
    )

    const getPayloadContent = (values) => {

        return {
            path: "parkingdevice" + ((isEditMode) ? "" : "/v2/"+parentEntityID),
            entity: {
                DeviceID        : entityID,
                ParentEntityID  : parentEntityID,
                Name            : values.name,
                description     : values.description,
            }
        };

    };

    const getSettingPayload = (deviceCreatedID) => {
        return [{
            userName: currentUser.EmailAddress,
            entityId: deviceCreatedID,
            settingName: "setting.devicemode",
            settingValue: "Kiosk"
        }];
    };

    const genericSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, "Minimum of 2 characters")
          .max(50, "Maximum of 50 characters")
          .required("Required"),
        description: Yup.string()
          .max(200, "Maximum of 200 characters")
          .nullable(),
      });

    //if mode is "create", the entity id is the parent entity id
    //if mode is "create", look for another prop of type
    //if mode is "edit", entityid is the editing entity id
    
    useEffect(()=> {//if user or entity changes
         return () => {}
    },[entity]);

    console.log("entity in generic", entity, isEditMode);
  
    return (
        <div style={{padding:10}} data-testid="DeviceEditorTitle">
            <Title><span className={classes.camelCase}>{(isEditMode) ? "Edit Device":"Create Device"}</span></Title>
            <Divider style={{ marginBottom: 15 }}></Divider>        
            <Formik
                onSubmit={async (values) => {
                    values = {...values, name: values.name?.trim()};
                    var _payload = getPayloadContent(values);
                    let result;
                    let secondaryUpdateError = false;
                    if (isEditMode) {
                        let resp;
                        try {
                            resp = await deviceService.getEntity(_payload.entity.ParentEntityID, _payload.entity.DeviceID);
                        } catch {
                            secondaryUpdateError = true;
                        }
                        if (!secondaryUpdateError) {
                            _payload.entity.DestinationEntityID = resp.data?.destinationEntityID ? resp.data?.destinationEntityID : null;
                            result = await dispatch(entityUpdate(_payload));
                        }
                    } else {
                        if (parentEntityData.typeID == c.ENTITY_TYPE.Area) {
                            _payload.entity.DestinationEntityID = parentEntityData.parentEntityID;
                        } else {
                            _payload.entity.DestinationEntityID = _payload.entity.ParentEntityID;
                        }
                        result = await dispatch(entityCreate(_payload));
                        if (result.payload?.entityid) {
                            // Set the new device's mode as Kiosk (also known as POF, neutral or Central)
                            // NOTE:  result.payload.entityid contains the ID of the newly created device
                            result = await dispatch(entitySettingsUpdate(getSettingPayload(result.payload.entityid)));
                        }
                    }
                    console.log("Saving", _payload);

                    console.log("Done Saving, result", result);
                    if (secondaryUpdateError || result.error) {
                        enqueueSnackbar("Unable to save updates.  Please try again.", {
                            variant: "error",
                            tag: "FailedToSaveUpdates",
                          });
                    }else{
                        onSaveComplete();
                    }
                    
                }}
                enableReinitialize
                validateOnChange={true}
                
                initialValues={{
                    name: entity.name,
                    description: (isEditMode) ? entity.details?.description : entity.description,
                }}            
                validationSchema={genericSchema}
            >     
            {({ isSubmitting, handleChange }) => (
                <Form style={{width:"100%"}}>
                    <Grid container spacing={2} style={{ width:"100%"}}>
                        <Grid item xs={12}>
                            <Field data-testid="nameField" fullWidth variant="outlined" label="Name" name="name" component={TextField}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                data-testid="descriptionField"
                                fullWidth 
                                multiline
                                rowsMax={4}
                                variant="outlined" 
                                label="Description" 
                                name="description" 
                                component={TextField}/>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"right"}}>
                            <Button
                                data-testid="submitButton"
                                style={{marginRight:10}}
                                type="submit"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                color="primary"
                                disabled = {isSubmitting}
                                >
                                {isEditMode ? "Update" : "Create"}
                            </Button>
                            <Button
                                startIcon={<CancelIcon />}
                                variant="contained"
                                onClick={onCancel}
                                >
                                Cancel
                            </Button>                        
                        </Grid>

                    </Grid>
                </Form>
            )}
            </Formik>              
        </div>
            
    );
}


DeviceEditor.defaultProps   = {entityID: null, parentEntityID: null, parentEntityData: undefined,};
DeviceEditor.propTypes      = {entityID: PropTypes.string, parentEntityID:PropTypes.string, parentEntityData:PropTypes.object,};

export default DeviceEditor