import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => {
  return {
    camelCase: {
        textTransform:"capitalize",
    },
    deleteBtnGrid: {
      textAlign: "right",
      "& button": {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
      },
    },
    root: {},
    timeZone: {},
    grouping: {
      borderRadius: "2%",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      flexFlow: "column wrap",
      "& div": {
        display: "flex",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },
    inline: {
      display: "inline-flex",
    },
    giveSpace: {
      margin: "5px",
      width: "calc(100%)",
    },
    sameRow: {
      display: "inline-block",
      padding: "10px",
      margin: "20px",
    },
    error: {
      marginLeft: theme.spacing(2),
      fontSize: ".75rem",
      "& > label": {
        color: theme.palette.error?.main,
      },
    },
    btnGroup: {
      textAlign: "right",
      "& > *": {
        minWidth: "100px",
        margin: theme.spacing(2),
      },
    },
    top: {
      width: "100%",
      display: "inline-flex",
      justifyContent: "space-between",
      "& input": {
        minWidth: "250px",
      },
    },
  };
});

export default styles;
