import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => {
  return {
    camelCase: {
      textTransform: "capitalize",
    },
    divider: {
      marginBottom: 15,
    },
    submitButton: {
      marginRight: 10,
    },
  };
});

export default styles;
