import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, TextField } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { entityCreate, entityUpdate } from "../../../state/slices/entities";
import * as Yup from "yup";
import PropTypes from "prop-types";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Title from "../../Title";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./AreaEditor.styles";
import apiClient from "../../../auth/apiClient";
import AreaService from "../../../services/AreaService";
import clsx from "clsx";

export const validationSchema = Yup.object().shape({
  areaName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Cannot exceed 100 characters")
    .required("Required"),
});

const AreaEditor = ({
  entityID,
  parentEntityID,
  onSubmitComplete,
  onCancel,
}) => {
  const classes = styles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const dispatch = useDispatch();
  const areaService = new AreaService(apiClient);

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (entityID) {
      const fetchEntity = async () => {
        try {
          const response = await areaService.getEntity(parentEntityID, entityID);
          if (response?.data) {
            setValue("areaName", response.data.name);
          }
        } catch (error) {
          console.error("Failed to fetch entity:", error);
        }
      };
      fetchEntity();
    }
  }, [entityID, setValue]);

  const onFormValueChange = (e) => {
    setValue(e.target.name, e.target.value.trim());
  };

  const createOrUpdateEntity = async (data) => {
    const payload = {
      ParentEntityId: parentEntityID,
      Name: data.areaName,
      AreaID: entityID,
    };
    const result = entityID
      ? await dispatch(entityUpdate({
          path: "area",
          entity: payload,
      }))
      : await dispatch(entityCreate({
          path: `area/${parentEntityID}`,
          entity: payload,
      }));

    if (result?.error) {
      enqueueSnackbar(
        `We encountered an error while ${entityID ? "updating" : "creating"} the area`,
        { variant: "error", tag: "FailedToUpdateArea" }
      );
    } else {
      onSubmitComplete();
    }
  };

  return (
    <>
      <div style={{ minWidth:"400px"}} data-testid="AreaEditorTitle">
        <Title>
          <span className={classes.camelCase}>
            {entityID ? "Edit Area" : "Create Area"}
          </span>
        </Title>
      </div>

      <Divider className={classes.divider}></Divider>

      <form
        onSubmit={handleSubmit(
          async (data) => await createOrUpdateEntity(data)
        )}
      >
        <Grid className={clsx([classes.areaBox])}>
        <Controller
          name="areaName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-testid="areaNameField"
              id="areaNameField"
              variant="outlined"
              margin="normal"
              fullWidth
              type="text"
              label="Name"
              autoFocus
              InputLabelProps={{ shrink: field.value }}
              onBlur={onFormValueChange}
              error={!!errors.areaName}
              helperText={
                errors.areaName && errors.areaName.message
              }
            />
          )}
        />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            data-testid="submitButton"
            className={clsx(classes.submitButton, "save")}
            type="submit"
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {entityID ? "Update" : "Create"}
          </Button>
          <Button
            startIcon={<CancelIcon />}
            variant="contained"
            onClick={onCancel}
            className={clsx("cancel")}
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </>
  );
};

AreaEditor.defaultProps = {
  onSubmitComplete: () => { },
  onCancel: () => { },
};

AreaEditor.propTypes = {
  entityID: PropTypes.string,
  parentEntityID: PropTypes.string,
  onSubmitComplete: PropTypes.func,
  onCancel: PropTypes.func,
};
export default AreaEditor;
