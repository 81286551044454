import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { PAYMENT_GATEWAY } from "../../../constants";

const PlanetSelfSubmitForm = ({ planetRequestResponse, hostBase }) => {
  const formRef = useRef(null);

  useEffect(() => {
    //Auto submit form to redirect to Planet payment as soon as user clicks on ADD button if Payment gateway is Planet
    if(formRef.current && planetRequestResponse?.gatewaySettings?.SelfSubmitFormURL) {
      formRef.current.submit();
    }
  }, [planetRequestResponse.gateway === PAYMENT_GATEWAY.PLANET]);

  return (
        <form ref={formRef} method="post" data-testid="autosubmit-form" action={planetRequestResponse?.gatewaySettings?.SelfSubmitFormURL}>
          <input type="hidden" name="XXX_IPGSESSION_XXX" value={planetRequestResponse?.sessionID}/>
          <input type="hidden" name="merchant_script_data_1" value={hostBase}/>
          <input type="hidden" name="content_language" value="EN"/>
        </form>
    )
};

PlanetSelfSubmitForm.propTypes = {
  hostBase: PropTypes.string,
  planetRequestResponse: PropTypes.object
};

export default PlanetSelfSubmitForm;