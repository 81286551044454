export const transformFacilityDataIngress = (data) => {
  const addressInfo = (prefix, address) => {
    return {
      [`${prefix}Address1`]: address.addressLine1,
      [`${prefix}Address2`]: address.addressLine2,
      [`${prefix}CountryID`]: address.countryID,
      [`${prefix}State`]: address.state,
      [`${prefix}SubdivisionID`]: address.subdivisionID,
      [`${prefix}Zip`]: address.postalCode,
      [`${prefix}City`]: address.city,
    };
  };

  const nameInfo = (prefix, name) => {
    return {
      [`${prefix}FirstName`]: name.firstName,
      [`${prefix}LastName`]: name.lastName,
    };
  };

  const emailInfo = (prefix, email) => {
    return {
      [`${prefix}EMailAddress`]: email.emailAddress,
    };
  };

  const phoneInfo = (prefix, phones) => {
    const primary = phones.find((x) => x.isPrimary === true);
    const secondary = phones.find((x) => x.isPrimary === false);
    return {
      [`${prefix}Phone1`]: primary?.number ?? "",
      [`${prefix}Phone2`]: secondary?.number ?? "",
    };
  };

  const facAddress =
    data.facilityContact?.addresses?.length > 0
      ? addressInfo("Facility", data.facilityContact.addresses[0])
      : {};
  const primaryContactAddress =
    data.primaryContact?.addresses?.length > 0
      ? addressInfo("Primary", data.primaryContact.addresses[0])
      : {};
  const secondaryContactAddress =
    data.primaryContact?.addresses?.length > 0
      ? addressInfo("Secondary", data.secondaryContact.addresses[0])
      : {};

  const primaryNameInfo = data.primaryContact
    ? nameInfo("Primary", data.primaryContact)
    : {};
  const secondaryNameInfo = data.secondaryContact
    ? nameInfo("Secondary", data.secondaryContact)
    : {};

  const primaryEmailInfo =
    data.primaryContact?.emails?.length > 0
      ? emailInfo("Primary", data.primaryContact.emails[0])
      : {};
  const secondaryEmailInfo =
    data.secondaryContact?.emails?.length > 0
      ? emailInfo("Secondary", data.secondaryContact.emails[0])
      : {};

  const primaryPhoneInfo =
    data.primaryContact?.phoneNumbers?.length > 0
      ? phoneInfo("Primary", data.primaryContact.phoneNumbers)
      : {};
  const secondaryPhoneInfo =
    data.secondaryContact?.phoneNumbers?.length > 0
      ? phoneInfo("Secondary", data.secondaryContact.phoneNumbers)
      : {};

  return {
    FacilityName: data.name,
    TimeZone: data.timeZone ?? "",
    ...facAddress,
    ...primaryNameInfo,
    ...primaryContactAddress,
    ...primaryEmailInfo,
    ...primaryPhoneInfo,
    ...secondaryNameInfo,
    ...secondaryContactAddress,
    ...secondaryEmailInfo,
    ...secondaryPhoneInfo,
  };
};

export const transformFacilityDataEgress = (data) => {
  return {
    Name: data.FacilityName,
    TimeZone: data.TimeZone ?? "",
    FacilityContact: {
      Addresses: [
        {
          AddressLine1: data.FacilityAddress1,
          AddressLine2: data.FacilityAddress2,
          CountryID: data.FacilityCountryID,
          State: data.FacilityState,
          PostalCode: data.FacilityZip,
          City: data.FacilityCity,
          SubdivisionID: data.FacilitySubdivisionID,
        },
      ],
    },
    PrimaryContact: {
      FirstName: data.PrimaryFirstName,
      LastName: data.PrimaryLastName,
      Addresses: [
        {
          AddressLine1: data.PrimaryAddress1,
          AddressLine2: data.PrimaryAddress2,
          CountryID: data.PrimaryCountryID,
          State: data.PrimaryState,
          PostalCode: data.PrimaryZip,
          City: data.PrimaryCity,
          SubdivisionID: data.PrimarySubdivisionID,
        },
      ],
      PhoneNumbers: [
        {
          Number: data.PrimaryPhone1,
          IsPrimary: true,
        },
        {
          Number: data.PrimaryPhone2,
          IsPrimary: false,
        },
      ],
      Emails: [
        {
          EmailAddress: data.PrimaryEMailAddress,
        },
      ],
    },
    SecondaryContact: {
      FirstName: data.SecondaryFirstName,
      LastName: data.SecondaryLastName,
      Addresses: [
        {
          AddressLine1: data.SecondaryAddress1,
          AddressLine2: data.SecondaryAddress2,
          CountryID: data.SecondaryCountryID,
          State: data.SecondaryState,
          PostalCode: data.SecondaryZip,
          City: data.SecondaryCity,
          SubdivisionID: data.SecondarySubdivisionID,
        },
      ],
      PhoneNumbers: [
        {
          Number: data.SecondaryPhone1,
          IsPrimary: true,
        },
        {
          Number: data.SecondaryPhone2,
          IsPrimary: false,
        },
      ],
      Emails: [
        {
          EmailAddress: data.SecondaryEMailAddress,
        },
      ],
    },
  };
};

export const transformValetAreaDataIngress = (data) => {
  const addressInfo = (prefix, address) => {
    return {
      [`${prefix}Address1`]: address.addressLine1,
      [`${prefix}Address2`]: address.addressLine2,
      [`${prefix}CountryID`]: address.countryID,
      [`${prefix}State`]: address.state,
      [`${prefix}SubdivisionID`]: address.subdivisionID,
      [`${prefix}Zip`]: address.postalCode,
      [`${prefix}City`]: address.city,
    };
  };

  const nameInfo = (prefix, name) => {
    return {
      [`${prefix}FirstName`]: name.firstName,
      [`${prefix}LastName`]: name.lastName,
    };
  };

  const emailInfo = (prefix, email) => {
    return {
      [`${prefix}EMailAddress`]: email.emailAddress,
    };
  };

  const phoneInfo = (prefix, phones) => {
    const primary = phones.find((x) => x.isPrimary === true);
    const secondary = phones.find((x) => x.isPrimary === false);
    return {
      [`${prefix}Phone1`]: primary?.number ?? "",
      [`${prefix}Phone2`]: secondary?.number ?? "",
    };
  };

  const valetareaAddress =
    data.valetAreaContact?.addresses?.length > 0
      ? addressInfo("ValetArea", data.valetAreaContact.addresses[0])
      : {};
  const primaryContactAddress =
    data.primaryContact?.addresses?.length > 0
      ? addressInfo("Primary", data.primaryContact.addresses[0])
      : {};
  const secondaryContactAddress =
    data.primaryContact?.addresses?.length > 0
      ? addressInfo("Secondary", data.secondaryContact.addresses[0])
      : {};

  const primaryNameInfo = data.primaryContact
    ? nameInfo("Primary", data.primaryContact)
    : {};
  const secondaryNameInfo = data.secondaryContact
    ? nameInfo("Secondary", data.secondaryContact)
    : {};

  const primaryEmailInfo =
    data.primaryContact?.emails?.length > 0
      ? emailInfo("Primary", data.primaryContact.emails[0])
      : {};
  const secondaryEmailInfo =
    data.secondaryContact?.emails?.length > 0
      ? emailInfo("Secondary", data.secondaryContact.emails[0])
      : {};

  const primaryPhoneInfo =
    data.primaryContact?.phoneNumbers?.length > 0
      ? phoneInfo("Primary", data.primaryContact.phoneNumbers)
      : {};
  const secondaryPhoneInfo =
    data.secondaryContact?.phoneNumbers?.length > 0
      ? phoneInfo("Secondary", data.secondaryContact.phoneNumbers)
      : {};

  return {
    ValetAreaName: data.name,
    TimeZone: data.timeZone ?? "",
    ...valetareaAddress,
    ...primaryNameInfo,
    ...primaryContactAddress,
    ...primaryEmailInfo,
    ...primaryPhoneInfo,
    ...secondaryNameInfo,
    ...secondaryContactAddress,
    ...secondaryEmailInfo,
    ...secondaryPhoneInfo,
  };
  };

export const transformValetAreaDataEgress = (data) => {
  return {
    Name: data.ValetAreaName,
    TimeZone: data.TimeZone ?? "",
    ValetAreaContact: {
      Addresses: [
        {
          AddressLine1: data.ValetAreaAddress1,
          AddressLine2: data.ValetAreaAddress2,
          CountryID: data.ValetAreaCountryID,
          State: data.ValetAreaState,
          PostalCode: data.ValetAreaZip,
          City: data.ValetAreaCity,
          SubdivisionID: data.ValetAreaSubdivisionID,
        },
      ],
    },
    PrimaryContact: {
      FirstName: data.PrimaryFirstName,
      LastName: data.PrimaryLastName,
      Addresses: [
        {
          AddressLine1: data.PrimaryAddress1,
          AddressLine2: data.PrimaryAddress2,
          CountryID: data.PrimaryCountryID,
          State: data.PrimaryState,
          PostalCode: data.PrimaryZip,
          City: data.PrimaryCity,
          SubdivisionID: data.PrimarySubdivisionID,
        },
      ],
      PhoneNumbers: [
        {
          Number: data.PrimaryPhone1,
          IsPrimary: true,
        },
        {
          Number: data.PrimaryPhone2,
          IsPrimary: false,
        },
      ],
      Emails: [
        {
          EmailAddress: data.PrimaryEMailAddress,
        },
      ],
    },
    SecondaryContact: {
      FirstName: data.SecondaryFirstName,
      LastName: data.SecondaryLastName,
      Addresses: [
        {
          AddressLine1: data.SecondaryAddress1,
          AddressLine2: data.SecondaryAddress2,
          CountryID: data.SecondaryCountryID,
          State: data.SecondaryState,
          PostalCode: data.SecondaryZip,
          City: data.SecondaryCity,
          SubdivisionID: data.SecondarySubdivisionID,
        },
      ],
      PhoneNumbers: [
        {
          Number: data.SecondaryPhone1,
          IsPrimary: true,
        },
        {
          Number: data.SecondaryPhone2,
          IsPrimary: false,
        },
      ],
      Emails: [
        {
          EmailAddress: data.SecondaryEMailAddress,
        },
      ],
    },
  };
  };